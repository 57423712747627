<template>
  <div class="container live">
    <div class="tags">
      <div class="tags-tit">全部分类{{ liveList.length }}</div>
      <div :class="['tag', active == item.value ? 'active' : '']" v-for="(item, index) in tagList" :key="item + index"
        @click="checkTag(item.value)">
        {{ item.text }}
      </div>
    </div>
    <div class="live-content">
      <div class="live-div" v-for="(item, index) in selectList" :key="index + 'live'" @mouseover="showPlay = item"
        @mouseout="showPlay = ''" @click="goLive(item)">
        <div class="img">
          <img :src="item.cover" />
          <div class="over-div" v-show="showPlay == item">
            <img src="../assets/img/play.png" />
          </div>
        </div>
        <div class="live-text">
          <div class="name">{{ item.title }}</div>
          <div class="num">
            <img src="../assets/img/people.png" />
            {{ item.num }}
          </div>
        </div>
      </div>
    </div>
    <Page :total="liveList.length" style="margin:auto;text-align:center;margin-bottom:40px" @on-change="handlePage"
      v-if="active === ''" :current="current" :page-size="size" show-elevator />

    <Modal v-model="isAdult" :closable="false" ok-text="是" cancel-text="否" class-name="adult-modal" width="280"
      :mask-closable="false">
      <div slot="title"></div>
      <div class="content">
        <div class="img-box">
          <img src="../assets/img/adult-protect.png" alt="" />
        </div>
        <div class="bold-text">你是否年满18周岁?</div>
        <div class="gray-text">未成年人每日观看时长不超过4小时</div>
        <div class="btn-box">
          <div class="btnV no" @click="handleCheck(1)">否</div>
          <div class="fgx"></div>
          <div class="btnV yes" @click="handleCheck(2)">是</div>
        </div>
      </div>
      <div slot="footer"></div>
    </Modal>

    <Modal v-model="teenagerModel" :closable="false" ok-text="是" cancel-text="否" class-name="adult-modal" width="280"
      :mask-closable="false">
      <div slot="title"></div>
      <div class="content">
        <div class="bold-text">青少年模式</div>
        <div class="gray-text">青少年模式中，我们精选了一批适合青少年观看的内容，且无法进行购买、打赏等操作。禁用时间内无法观看直播，单日观看时间不操过4小时</div>
        <div class="btn-box">
          <div class="btnTeen" @click="handleCheckTeen">开启青少年模式</div>
        </div>
      </div>
      <div slot="footer"></div>
    </Modal>
  </div>
</template>
<style lang="scss" scoped>
.live {
  height: 100%;
}

.tags {
  display: flex;
  align-items: center;
  padding: 40px 0;

  .tags-tit {
    width: 96px;
    height: 34px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 34px;
    width: auto;
    margin-right: 20px;
  }

  .tag {
    background-color: #ffffff;
    border-radius: 12px;
    border: 1px solid #e2e2e2;
    padding: 3px 10px;
    width: auto;
    display: inline-block;
    margin-right: 10px;
    font-size: 12px;
    cursor: pointer;
  }

  .active {
    color: #20a0ff;
    border: 1px solid #20a0ff;
  }
}

.live-content {
  display: flex;
  flex-wrap: wrap;
  width: 1100px;
  margin-bottom: 40px;

  .live-div {
    width: 188px;
    height: 146px;
    background: #ffffff;
    border-radius: 4px;
    margin-right: 15px;
    position: relative;
    cursor: pointer;
    margin-bottom: 15px;

    .img {
      width: 188px;
      height: 106px;
      border-radius: 3px 3px 0px 0px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .live-text {
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: space-between;
      padding: 12px;
      box-sizing: border-box;

      .name {
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        line-height: 18px;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .num {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 18px;

        img {
          width: 12px;
          height: 12px;
        }
      }
    }

    .over-div {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 106px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 3px 3px 0px 0px;

      img {
        width: 40px;
        height: 40px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
<style lang="scss">
.adult-modal {

  .ivu-modal-header,
  .ivu-modal-footer,
  .ivu-modal-close {
    display: none;
  }

  .content {
    background-color: #ffffff;

    .img-box {
      margin-top: 24px;
      margin-bottom: 12px;
      text-align: center;
    }

    .bold-text {
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-bottom: 10px;
    }

    .gray-text {
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }

    .btn-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px 0;

      .fgx {
        width: 16px;
      }

      .btnV {
        text-align: center;
        box-sizing: border-box;
        width: 96px;
        height: 32px;
        line-height: 32px;
        border-radius: 16px;
        border: 1px solid #20a0ff;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        cursor: pointer;

        &.no {
          border: 1px solid #20a0ff;
          background-color: #ffffff;
          color: #20a0ff;
        }

        &.yes {
          background-color: #20a0ff;
          color: #ffffff;
        }
      }

      .btnTeen {
        width: 178px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #20A0FF;
        border-radius: 16px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }
}
</style>
<script>
import LiveList from '../constant/live'

export default {
  data() {
    return {
      isAdult: false, // 是否未成年
      teenagerModel: false, // 青少年模式
      tagList: [
        { text: '全部', value: '' },
        // { text: '百科', value: '百科' },
        { text: '美术', value: '美术' },
        // { text: '体育', value: '体育' },
        // { text: '科普', value: '科普' },
        { text: '演艺', value: '演艺' },
        { text: '其他', value: '其他' },
      ],
      active: '',
      showPlay: '',
      liveList: LiveList,
      selectList: [],
      current: 1,
      size: 60,
    }
  },
  mounted() {
    if (!sessionStorage.getItem('isCheck')) {
      this.isAdult = true
    }
    this.liveList.map((item) => {
      this.$set(
        item,
        'num',
        parseInt(Math.random() * (5000 - 100 + 1) + 100, 10)
      )
    })
    this.liveList.sort((a, b) => {
      return b.num - a.num
    })
    if (sessionStorage.getItem('isCheck') === 1) {
      this.liveList = this.liveList.filter((item) => {
        return item.show === undefined
      })
    }
    // this.selectList = this.liveList.filter((_) => _.cover)
    this.handlePage(1)
  },
  methods: {
    handleCheck(isCheck) {
      sessionStorage.setItem('isCheck', isCheck)
      this.isAdult = false
      // 未成年：1，成年：2
      if (isCheck === 1) {
        setTimeout(() => {
          this.teenagerModel = true
        }, 300)
      }
    },
    handleCheckTeen() {
      this.teenagerModel = false
      this.liveList = this.liveList.filter((item) => {
        return item.show === undefined
      })
      this.handlePage(1)
    },
    checkTag(val) {
      this.active = val
      if (val === '') {
        // this.current = 1
        // this.selectList = this.liveList.filter((_) => _.cover)
        this.handlePage(1)
      } else {
        this.selectList = this.liveList.filter((item) => {
          return item.type === val && item.cover
        })
      }
    },
    goLive(item) {
      sessionStorage.setItem('detail', JSON.stringify(item))
      const routeData = this.$router.resolve({ path: '/detail' })
      window.open(routeData.href, '_blank')
    },
    handlePage(size) {
      this.current = size
      const arr = this.deepClone(this.liveList)
      this.selectList = arr.splice((this.current - 1) * 60, 60)
      this.$nextTick(() => {
        window.scrollTo(0, 0)
      })
    },
    deepClone(source) {
      if (!source && typeof source !== 'object') {
        throw new Error('error arguments', 'deepClone')
      }
      const targetObj = source.constructor === Array ? [] : {}
      Object.keys(source).forEach((keys) => {
        if (source[keys] && typeof source[keys] === 'object') {
          targetObj[keys] = this.deepClone(source[keys])
        } else {
          targetObj[keys] = source[keys]
        }
      })
      return targetObj
    },
  },
}
</script>
