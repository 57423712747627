const liveList = [
  // {
  //   id: 3,
  //   type: '百科',
  //   title: '爱迪科森',
  //   describe: '许密杉：英语四六级《阅读理解王炸解题思路》',
  //   avatar: require('../assets/img/avatar/3.png'),
  //   cover: require('../assets/img/M1_BK/M1_BK_03.png'),
  //   url: '//cdn-host.media.yunxi.tv/recordM3u8/test_fbad6c68086c4a828f1f32ea6b8067da/9fecfd5770e24ec1a17960b251ea9318.m3u8?aliyun_uuid=343d6b2aaa264dd0b39c5b48b3bd2627',
  //   show: false,
  // },
  // {
  //   id: 8,
  //   type: '百科',
  //   title: '红河锡都影视传媒',
  //   describe: '潘文婷(微党课)',
  //   avatar: require('../assets/img/avatar/8.png'),
  //   cover: require('../assets/img/M1_BK/M1_BK_08.png'),
  //   url: '//image.yunxi.tv/recordVideoM3u8/20123/f75fd0bccc194759a1a59e36f4b7e608.m3u8',
  //   show: false,
  // },
  // {
  //   id: 12,
  //   type: '百科',
  //   title: '上海新东方',
  //   describe: '国际课程demo课',
  //   avatar: require('../assets/img/avatar/12.png'),
  //   cover: require('../assets/img/M1_BK/M1_BK_12.png'),
  //   url: '//cdn-host.media.yunxi.tv/recordM3u8/test_2a30c83104b94bf3b07b0f35d47b3975/d1da16b3ed094370bc1ebde11eaadbc1.m3u8?aliyun_uuid=a92ba4b6d72a4a1097e18b739156d3a3',
  //   show: false,
  // },
  // {
  //   id: 14,
  //   type: '百科',
  //   title: '太阳神直播',
  //   describe: '南宁晚报2021升学季特别策划',
  //   avatar: require('../assets/img/avatar/14.png'),
  //   cover: require('../assets/img/M1_BK/M1_BK_14.png'),
  //   url: '//cdn-host.media.yunxi.tv/recordM3u8/test_12b5bc17a30248b983bca8a1dab917df/292260a29fc349f5a55fb5593ebbc0f1.m3u8?aliyun_uuid=1d6ac40abc3e4090b42b53f2b47d9f1f',
  //   show: false,
  // },
  // {
  //   id: 15,
  //   type: '百科',
  //   title: '新东方合肥学校',
  //   describe: '安庆路幼儿园教育集团大班家长课堂',
  //   avatar: require('../assets/img/avatar/15.png'),
  //   cover: require('../assets/img/M1_BK/M1_BK_15.png'),
  //   url: '//cdn-host.media.yunxi.tv/recordM3u8/test_cfae025b38dd425c9c001adc0e35b19c/77e8f01180fd439fbd317d729290b3a2.m3u8?aliyun_uuid=c6fb5fa0e08a406bbc985b6be8023566',
  //   show: false,
  // },
  // {
  //   id: 17,
  //   type: '百科',
  //   title: 'hello工作室',
  //   describe: '直播实用技巧',
  //   avatar: require('../assets/img/avatar/2.png'),
  //   cover: require('../assets/img/M1_BK/M1_BK_17.png'),
  //   url: '',
  //   show: false,
  // },
  // {
  //   id: 22,
  //   type: '百科',
  //   title: '先健',
  //   describe: '230空中课堂--知识科普科普',
  //   avatar: require('../assets/img/avatar/7.png'),
  //   cover: require('../assets/img/M1_BK/M1_BK_22.png'),
  //   url: '',
  //   show: false,
  // },
  // {
  //   id: 26,
  //   type: '百科',
  //   title: '政务直播',
  //   describe: '2020年度个人所得税综合所得汇算清缴新变化与热点问题解析',
  //   avatar: require('../assets/img/avatar/11.png'),
  //   cover: require('../assets/img/M1_BK/M1_BK_26.png'),
  //   url: '//cdn-host.media.yunxi.tv/recordM3u8/c9c5d4eaf1454d9faeb6b3a57c55c433/tranbox/a833c4d04e644b70ad9d9fbf2141808b_uG0j1.m3u8?aliyun_uuid=4b607c77a86948aeb00ee02d5f775520',
  //   show: false,
  // },
  // {
  //   id: 30,
  //   type: '百科',
  //   title: '共享学院',
  //   describe: '铸造人才——共赢装备制造有限公司',
  //   avatar: require('../assets/img/avatar/15.png'),
  //   cover: require('../assets/img/M1_BK/M1_BK_30.png'),
  //   url: '//cdn-host.media.yunxi.tv/recordM3u8/test_ad840ee5a0da477fa88dfcec98dd1382/9e3c5f6c71bf4bc3b2c5c6b2097957f7.m3u8?aliyun_uuid=6b0d4888e62745c3b2c24e0111103427',
  //   show: false,
  // },
  // {
  //   id: 32,
  //   type: '百科',
  //   title: '无敌大三脚猫',
  //   describe: '如何帮助孩子掌握英语学习方法，培养学习兴趣！',
  //   avatar: require('../assets/img/avatar/17.png'),
  //   cover: require('../assets/img/M1_BK/M1_BK_32.png'),
  //   url: '//cdn-host.media.yunxi.tv/recordM3u8/test_c732ba34d5d6404297503a1f65ad7033/d3ecd0fbc8e741d5b1449a469131c35d.m3u8?aliyun_uuid=7c756ae0b18544b5b2d89ebbfe9b4b26',
  // },
  // {
  //   id: 33,
  //   type: '百科',
  //   title: '志愿填报找勋哥',
  //   describe: '4点到5点30',
  //   avatar: require('../assets/img/avatar/1.png'),
  //   cover: require('../assets/img/M1_BK/M1_BK_33.png'),
  //   url: '',
  //   show: false,
  // },
  // {
  //   id: 35,
  //   type: '百科',
  //   title: '名医在线直播',
  //   describe: '毕业遇上疫情，大学生别慌！',
  //   avatar: require('../assets/img/avatar/3.png'),
  //   cover: require('../assets/img/M1_BK/M1_BK_35.png'),
  //   url: '',
  //   show: false,
  // },
  // {
  //   id: 37,
  //   type: '百科',
  //   title: '巨明教育',
  //   describe: '四川省美术高考志愿填报讲座-主讲人：王强',
  //   avatar: require('../assets/img/avatar/5.png'),
  //   cover: require('../assets/img/M1_BK/M1_BK_37.png'),
  //   url: '',
  //   show: false,
  // },
  // {
  //   id: 2,
  //   type: '健康科普',
  //   title: '廖茂钦',
  //   describe: '如何提高免疫力',
  //   avatar: require('../assets/img/avatar/11.png'),
  //   cover: require('../assets/img/M1_BK/M1_BK_14.png'),
  //   url: '//cdn-host.media.yunxi.tv/recordM3u8/test_07b021aec78d42db905493d8bc01ea63/d3fab15e73314b42b6b3aa175292df4e.m3u8?aliyun_uuid=1dcffcb9015e4349ae9a89eda7e28cc1',
  //   show: false,
  // },
  // {
  //   id: 4,
  //   type: '健康科普',
  //   title: '中科体检',
  //   describe: '食光静好—糖尿病健康饮食',
  //   avatar: require('../assets/img/avatar/13.png'),
  //   cover: require('../assets/img/M4_JKKP/M4_JKKP_04.png'),
  //   url: '//cdn-host.media.yunxi.tv/recordClip/16031/video/7ab842b31558476d816f14dc626c78e0/2b9d7cc059c24077aad4cc4662bfaab1_stT8u.m3u8?aliyun_uuid=2889739572f242828067e2d528c4d88e',
  //   show: false,
  // },
  // {
  //   id: 6,
  //   type: '健康科普',
  //   title: '雅莎尔复因',
  //   describe: '皮肤屏障的影响因素及修复',
  //   avatar: require('../assets/img/avatar/15.png'),
  //   cover: require('../assets/img/M4_JKKP/M4_JKKP_06.png'),
  //   url: '//cdn-host.media.yunxi.tv/recordM3u8/test_d801c9f296034f0fafe4636d461866f8/c323c2fe15b34ccd91926aa81133b887.m3u8?aliyun_uuid=b0965084307f495b84cf2d40bd729e73',
  //   show: false,
  // },
  // {
  //   id: 7,
  //   type: '健康科普',
  //   title: '大理州妇幼保健院',
  //   describe: '孕期饮食禁忌与营养素',
  //   avatar: require('../assets/img/avatar/16.png'),
  //   cover: require('../assets/img/M4_JKKP/M4_JKKP_07.png'),
  //   url: '//cdn-host.media.yunxi.tv/recordM3u8/test_ccb1595be18445a58da326d4eb22027b/099d07a01cf944b78f1cafafdc74c2a8.m3u8?aliyun_uuid=1e1a99a35b8e4e8eb5e094d9d44661fc',
  //   show: false,
  // },
  // {
  //   id: 8,
  //   type: '健康科普',
  //   title: '德轩堂',
  //   describe: '知识科普',
  //   avatar: require('../assets/img/avatar/17.png'),
  //   cover: require('../assets/img/M4_JKKP/M4_JKKP_08.png'),
  //   url: '//cdn-host.media.yunxi.tv/recordM3u8/test_fbddd864bbb24e69902964d691b679f1/ae200163e2dc4b7d802d5010af8377bb.m3u8?aliyun_uuid=25a8a68dc439450994d50e81d8401a0f',
  //   show: false,
  // },
  // {
  //   id: 10,
  //   type: '健康科普',
  //   title: '视频直播大师',
  //   describe: '疾病科普',
  //   avatar: require('../assets/img/avatar/2.png'),
  //   cover: require('../assets/img/M4_JKKP/M4_JKKP_10.png'),
  //   url: '',
  //   show: false,
  // },
  // {
  //   id: 11,
  //   type: '科普',
  //   title: '吃的学问',
  //   describe: '如何正确的吃',
  //   avatar: require('../assets/img/avatar/3.png'),
  //   cover: require('../assets/img/M4_JKKP/M4_JKKP_11.png'),
  //   url: '',
  // },
  // {
  //   id: 12,
  //   type: '健康科普',
  //   title: '养生课堂',
  //   describe: '怎样才是真养生',
  //   avatar: require('../assets/img/avatar/4.png'),
  //   cover: require('../assets/img/M4_JKKP/M4_JKKP_12.png'),
  //   url: '',
  // },
  // {
  //   id: 13,
  //   type: '健康科普',
  //   title: '养生粥',
  //   describe: '养生粥的正确煮法',
  //   avatar: require('../assets/img/avatar/5.png'),
  //   cover: require('../assets/img/M4_JKKP/M4_JKKP_13.png'),
  //   url: '',
  // },
  // {
  //   id: 14,
  //   type: '健康科普',
  //   title: '儿童营养学',
  //   describe: '关注儿童营养健康',
  //   avatar: require('../assets/img/avatar/6.png'),
  //   cover: require('../assets/img/M4_JKKP/M4_JKKP_14.png'),
  //   url: '',
  //   show: false,
  // },
  // {
  //   id: 15,
  //   type: '健康科普',
  //   title: '健康在线',
  //   describe: '如何预防骨质酥松',
  //   avatar: require('../assets/img/avatar/7.png'),
  //   cover: require('../assets/img/M4_JKKP/M4_JKKP_15.png'),
  //   url: '',
  //   show: false,
  // },
  // {
  //   id: 16,
  //   type: '健康科普',
  //   title: '老年健康',
  //   describe: '老年锻炼小课堂',
  //   avatar: require('../assets/img/avatar/8.png'),
  //   cover: require('../assets/img/M4_JKKP/M4_JKKP_16.png'),
  //   url: '',
  //   show: false,
  // },
  // {
  //   id: 17,
  //   type: '科普',
  //   title: '太极',
  //   describe: '杨氏太极基本教学',
  //   avatar: require('../assets/img/avatar/9.png'),
  //   cover: require('../assets/img/M4_JKKP/M4_JKKP_17.png'),
  //   url: '',
  //   show: false,
  // },
  {
    id: 11,
    type: '美术',
    title: '广州艺巢画室',
    describe: '6月13号速写课',
    avatar: require('../assets/img/avatar/11.png'),
    cover: require('../assets/img/M2_MS/M2_MS_11.png'),
    url: '//cdn-host.media.yunxi.tv/recordM3u8/test_8f5275e6d8f74ee18c839f21e3d1ab17/cbe72c236d7a45259e482c804c01a9f9.m3u8?aliyun_uuid=b5cb9e998a3043d39f92583927a0e1f1',
  },
  {
    id: 29,
    type: '美术',
    title: '青山影视',
    describe: '济南华润置地会油画大咖线上教学',
    avatar: require('../assets/img/avatar/3.png'),
    cover: require('../assets/img/M2_MS/M2_MS_29.png'),
    url: '',
    show: false,
  },
  {
    id: 34,
    type: '美术',
    title: '姜浩张超画室',
    describe: '色彩静物|如何画好氛围感（第二课）',
    avatar: require('../assets/img/avatar/7.png'),
    cover: require('../assets/img/M2_MS/M2_MS_34.png'),
    url: '//cdn-host.media.yunxi.tv/recordM3u8/test_a87ad1efb94b4498b4ce71b7252435a8/216aa9adb6bf4b5eb5fcb97df2639471.m3u8?aliyun_uuid=51294d7862fe46738c798badb2c76639',
    show: false,
  },
  {
    id: 32,
    type: '美术',
    title: '术心画室',
    describe: '术心画室2021年直播课程——单人坐姿动态分析讲解',
    avatar: require('../assets/img/avatar/7.png'),
    cover: require('../assets/img/M2_MS/M2_MS_32.png'),
    url: '',
    show: false,
  },
  {
    id: 2,
    type: '其他',
    title: '徐香慧',
    describe: '',
    avatar: require('../assets/img/avatar/9.png'),
    cover: require('../assets/img/M6_QT/M6_QT_02.png'),
    url: '',
    show: false,
  },
  {
    id: 3,
    type: '其他',
    title: '行走日记',
    describe: '一直在路上',
    avatar: require('../assets/img/avatar/10.png'),
    cover: require('../assets/img/M6_QT/M6_QT_03.png'),
    url: '',
    show: false,
  },
  {
    id: 4,
    type: '其他',
    title: '旅游的点滴',
    describe: '走入下一段风景，告别一段往事',
    avatar: require('../assets/img/avatar/11.png'),
    cover: require('../assets/img/M6_QT/M6_QT_04.png'),
    url: '',
    show: false,
  },
  {
    id: 5,
    type: '其他',
    title: '大街小巷',
    describe: '北京胡同的小故事',
    avatar: require('../assets/img/avatar/12.png'),
    cover: require('../assets/img/M6_QT/M6_QT_05.png'),
    url: '',
    show: false,
  },
  {
    id: 6,
    type: '其他',
    title: '随笔',
    describe: '人生不止眼前的苟且，还有诗和远方',
    avatar: require('../assets/img/avatar/13.png'),
    cover: require('../assets/img/M6_QT/M6_QT_06.png'),
    url: '',
    show: false,
  },
  {
    id: 7,
    type: '其他',
    title: '影视TV',
    describe: '经典电影合集',
    avatar: require('../assets/img/avatar/14.png'),
    cover: require('../assets/img/M6_QT/M6_QT_07.png'),
    url: '',
    show: false,
  },
  {
    id: 8,
    type: '其他',
    title: '剧播播',
    describe: '吃着零食看美剧',
    avatar: require('../assets/img/avatar/15.png'),
    cover: require('../assets/img/M6_QT/M6_QT_08.png'),
    url: '',
    show: false,
  },
  {
    id: 9,
    type: '其他',
    title: '逛吃逛吃',
    describe: '不负美食',
    avatar: require('../assets/img/avatar/16.png'),
    cover: require('../assets/img/M6_QT/M6_QT_09.png'),
    url: '',
    show: false,
  },
  {
    id: 10,
    type: '其他',
    title: '每日晨读',
    describe: '我爱学英语',
    avatar: require('../assets/img/avatar/1.png'),
    cover: require('../assets/img/M6_QT/M6_QT_10.png'),
    url: '',
  },
  {
    id: 11,
    type: '其他',
    title: '我是小小歌唱家',
    describe: '音乐的世界有你有我',
    avatar: require('../assets/img/avatar/2.png'),
    cover: require('../assets/img/M6_QT/M6_QT_11.png'),
    url: '',
  },
  {
    id: 12,
    type: '其他',
    title: '时光斑驳了容颜',
    describe: '皮肤需要好好呵护',
    avatar: require('../assets/img/avatar/3.png'),
    cover: require('../assets/img/M6_QT/M6_QT_12.png'),
    url: '',
    show: false,
  },
  {
    id: 13,
    type: '其他',
    title: '陪你到世界终结',
    describe: '不负如来不负卿',
    avatar: require('../assets/img/avatar/4.png'),
    cover: require('../assets/img/M6_QT/M6_QT_13.png'),
    url: '',
    show: false,
  },
  {
    id: 14,
    type: '其他',
    title: '不哭不闹不炫耀',
    describe: '每天都是现场直播',
    avatar: require('../assets/img/avatar/5.png'),
    cover: require('../assets/img/M6_QT/M6_QT_14.png'),
    url: '',
    show: false,
  },
  {
    id: 15,
    type: '其他',
    title: '1点旳思恋',
    describe: '你若安好，便是晴天',
    avatar: require('../assets/img/avatar/6.png'),
    cover: require('../assets/img/M6_QT/M6_QT_15.png'),
    url: '',
    show: false,
  },
  {
    id: 16,
    type: '其他',
    title: '全橙相伴',
    describe: '请叫我Π',
    avatar: require('../assets/img/avatar/7.png'),
    cover: require('../assets/img/M6_QT/M6_QT_16.png'),
    url: '',
    show: false,
  },
  {
    id: 17,
    type: '其他',
    title: '明月几时有',
    describe: '但愿人长久，千里共婵娟',
    avatar: require('../assets/img/avatar/8.png'),
    cover: require('../assets/img/M6_QT/M6_QT_17.png'),
    url: '',
    show: false,
  },
  {
    id: 18,
    type: '其他',
    title: '声声慢',
    describe: '冷冷清清凄凄惨惨戚戚',
    avatar: require('../assets/img/avatar/8.png'),
    cover: require('../assets/img/M6_QT/M6_QT_18.png'),
    url: '',
    show: false,
  },
  {
    id: 1,
    type: '演艺',
    title: '致中太极直播',
    avatar: require('../assets/img/avatar/9.png'),
    describe: '6.2 致中传统杨氏太极拳103式微信直播公益课程第45讲',
    cover: require('../assets/img/M5_QY/M5_QY_01.png'),
    url: '',
    show: false,
  },
  {
    id: 5,
    type: '演艺',
    title: '天津演艺网',
    describe: '刘红雁戏曲教学云课堂',
    avatar: require('../assets/img/avatar/13.png'),
    cover: require('../assets/img/M5_QY/M5_QY_05.png'),
    url: '//cdn-host.media.yunxi.tv/recordM3u8/5750f60dc851487b8423a14d48455887/5750f60dc851487b8423a14d48455887_wRWsQ.m3u8?aliyun_uuid=8fa77712b5fd4cb3b1a061219aa5422a',
    show: false,
  },
  {
    id: 34,
    type: '演艺',
    title: '在这里',
    describe: '一起舞动吧',
    avatar: require('../assets/img/avatar/13.png'),
    cover: require('../assets/img/dance/5MM_20210901110054.png'),
    url: 'https://yunxi-material-library.oss-cn-hangzhou.aliyuncs.com/wangwen/5MM.mp4',
    show: false,
  },
  {
    id: 35,
    type: '演艺',
    title: '扇舞的美',
    describe: '民族舞',
    avatar: require('../assets/img/avatar/13.png'),
    cover: require('../assets/img/dance/qianqian_20210901110446.png'),
    url: 'https://yunxi-material-library.oss-cn-hangzhou.aliyuncs.com/wangwen/%E8%8C%9C%E8%8C%9C.mp4',
    show: false,
  },
  {
    id: 36,
    type: '演艺',
    title: '林林',
    describe: '我在这里等你',
    cover: require('../assets/img/dance/linlin_20210901110309.png'),
    avatar: require('../assets/img/avatar/13.png'),
    url: 'https://yunxi-material-library.oss-cn-hangzhou.aliyuncs.com/wangwen/%E6%9E%97%E6%9E%97.mp4',
    show: false,
  },
  {
    id: 37,
    type: '演艺',
    title: '梦圆',
    describe: '圆梦时刻',
    cover: require('../assets/img/dance/tianyuanmeng_20210901112528.png'),
    avatar: require('../assets/img/avatar/13.png'),
    url: 'https://yunxi-material-library.oss-cn-hangzhou.aliyuncs.com/wangwen/%E7%94%B0%E6%A2%A6%E5%9C%86.mp4',
    show: false,
  },
  {
    id: 38,
    type: '演艺',
    title: '一寻',
    describe: '今天在跳舞',
    cover: require('../assets/img/dance/yixun_20210901112746.png'),
    avatar: require('../assets/img/avatar/13.png'),
    url: 'https://yunxi-material-library.oss-cn-hangzhou.aliyuncs.com/wangwen/%E4%B8%80%E5%AF%BB.MOV',
    show: false,
  },
  {
    id: 39,
    type: '演艺',
    title: '我是Joker',
    describe: '带你进入奇幻世界',
    cover: require('../assets/img/magic/moshushijoker_20210901113159.png'),
    avatar: require('../assets/img/avatar/13.png'),
    url: 'https://yunxi-material-library.oss-cn-hangzhou.aliyuncs.com/wangwen/%E9%AD%94%E6%9C%AF%E5%B8%88joker.mp4',
    show: false,
  },
  {
    id: 41,
    type: '演艺',
    title: '传统民乐演奏',
    describe: '笛独奏',
    cover: require('../assets/img/yueqi/di_20210901113504.png'),
    avatar: require('../assets/img/avatar/13.png'),
    url: 'https://yunxi-material-library.oss-cn-hangzhou.aliyuncs.com/wangwen/%E7%AC%9B.mp4',
    show: false,
  },
  {
    id: 42,
    type: '演艺',
    title: '民族乐器',
    describe: '这个乐器有点狂',
    cover: require('../assets/img/yueqi/feizhougu_20210901113638.png'),
    avatar: require('../assets/img/avatar/13.png'),
    url: 'https://yunxi-material-library.oss-cn-hangzhou.aliyuncs.com/wangwen/%E9%9D%9E%E6%B4%B2%E9%BC%93.mp4',
    show: false,
  },
  {
    id: 51,
    type: '演艺',
    title: '全都是泡沫',
    describe: '不一样的烟火',
    cover: require('../assets/img/songer/jiahui_20210901114517.png'),
    avatar: require('../assets/img/avatar/13.png'),
    url: 'https://yunxi-material-library.oss-cn-hangzhou.aliyuncs.com/wangwen/0824-7%E5%98%89%E8%BE%89.mov',
    show: false,
  },
  {
    id: 52,
    type: '演艺',
    title: '星辰大海',
    describe: '诗和远方的你',
    cover: require('../assets/img/songer/aimer_20210901114649.png'),
    avatar: require('../assets/img/avatar/13.png'),
    url: 'https://yunxi-material-library.oss-cn-hangzhou.aliyuncs.com/wangwen/0824-8Aimer.mov',
    show: false,
  },
  {
    id: 53,
    type: '演艺',
    title: '小布丁',
    describe: '吃货的快乐',
    cover: require('../assets/img/songer/rongrong_20210901114752.png'),
    avatar: require('../assets/img/avatar/13.png'),
    url: 'https://yunxi-material-library.oss-cn-hangzhou.aliyuncs.com/wangwen/0824-9%E8%93%89%E8%93%89.mp4',
    show: false,
  },
]

export default liveList
